import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SidePanel from "../components/SidePanel";

export const query = graphql`
  query ($id: ID!) {
    wp {
      page(id: $id) {
        id
        title
        content
      }
    }
  }
`;

const PageTemplate = ({ data }) => {
  const page = data.wp.page;
  return (
    <Layout title={page.title}>
      <div className="2xl:container relative lg:flex flex-wrap items-start justify-end lg:pr-36">
        <SidePanel title={page.title} />
        <div className="w-full flex flex-col items-center lg:items-end justify-start lg:mt-36">
          <div
            className="prose px-12 lg:pr-0 lg:w-[65%] py-12 lg:pl-32 lg:pb-24"
            dangerouslySetInnerHTML={{ __html: page.content }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default PageTemplate;
